import { database } from '../../../firebase.config.js' //Import firebase's realtime database instance so it's possible to push to and retrieve from it.
import { firebaseAction } from 'vuexfire' //Vuexfire action bundled with mutation to make call to firebase realtime's database and alter store state.

const state = {
    CurrentLivestream: '', //Hold current user user's flag lastest livestream url.
    CurrentGeoJSON: null, //Hold current GeoJSON from aerostat's GPS system if there's any.
    Users: [], //Hold list of users from database.
    Hirers: [], //Hold list of hirers from database.
    CurrentSauronLimits: {}, //Hold current user-imposed sauron limits for this user's current flag.
    GatewayMessages: [], //Hold lastest gateway messages.
    GatewayLastMessage: [], //Hold the exact last message from gateway.
    StopReport: {}, //Hold stop report from operator to tell if the aerostat is deployed or not.
    CameraTourStatus: {},
    WarningsCache: [],
    Equipments: [],
    OnlineUsers: [],
    Sites: [],
    Cameras: [],
    CurrentCamera:''
}

const getters = {
    GetCurrentLivestream(state) {
        return state.CurrentLivestream
    },
    GetCurrentGeoJSON(state) {
        return state.CurrentGeoJSON
    },
    GetHirers(state) {
        return state.Hirers
    },
    GetUsers(state) {
        return state.Users
    },
    GetCurrentSauronLimits(state) {
        return state.CurrentSauronLimits
    },
    GetGatewayMessages(state) {
        return state.GatewayMessages
    },
    GetGatewayLastMessage(state) {
        return state.GatewayLastMessage
    },
    GetStopReport(state) {
        return state.StopReport
    },
    GetCameraTourStatus(state) {
        return state.CameraTourStatus
    },
    GetWarningsCache(state) {
        return state.WarningsCache
    },
    GetEquipments(state) {
        return state.Equipments
    },
    GetOnlineUsers(state) {
        return state.OnlineUsers
    },
    GetSites(state) {
        return state.Sites
    },
    GetCurrentCamera(state) {
        return state.CurrentCamera
    },
    GetCameras(state) {
        return state.Cameras
    }
}

const mutations = {
    CLEAR_DATABASE_STORE(state) {
        state.CurrentLivestream = ''
        state.CurrentGeoJSON = null
        state.Hirers = []
        state.CurrentLivestream = null
        state.Users = []
        state.CurrentSauronLimits = []
        state.GatewayMessages = []
        state.GatewayLastMessage = []
        state.StopReport = []
        state.CameraTourStatus = []
        state.WarningsCache = []
        state.Equipments = []
        state.OnlineUsers = []
        state.Sites = []
    },
    SET_WARNINGS_CACHE(state, cache) {
        state.WarningsCache = cache
    }
}

const actions = {
    //Bind to streams branch on database based on user active flag.
    //Used to always display lastest stream added.
    bindCurrentLivestream: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('CurrentLivestream', database.ref('streams/' + flag.toLowerCase()).limitToLast(1))
    }),
    //Bind to hirers branch on database.
    //Used on an autocomplete for users to select hirers on forms.
    bindHirers: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('Hirers', database.ref('hirers'))
    }),
    //Bind to geojson branch on database based on user active flag.
    //Used to always display lastest geojson coming from Geocam.
    bindCurrentGeoJSON: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('CurrentGeoJSON', database.ref('locations/' + flag.toLowerCase()))
    }),
    unbindCurrentGeoJSON: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('CurrentGeoJSON')
    }),
    //Bind to users branch on database.
    //Used on a form to list all users for a user to select and edit them.
    bindUsers: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('Users', database.ref('users'))
    }),
    //Bind to sauron limits on database based on user active flag.
    //Used to synchronize sauron limits between all harpia clients with same active flag.
    bindSauronLimits: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('CurrentSauronLimits', database.ref('sauron/' + flag.toLowerCase() + '/limits'))
    }),
    unbindSauronLimits: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('CurrentSauronLimits')
    }),
    //Bind to gateway messages branch on database.
    //Used to display presence sensors' messages on a map.
    bindGatewayMessages: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('GatewayMessages', database.ref('gateways/devices'))
    }),
    //Bind to last gateway message branch on database.
    //Used to get last message emmited by a sensor and sound an alarm on a map based on message's type.
    bindGatewayLastMessage: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('GatewayLastMessage', database.ref('gateway-history/last-message'))
    }),
    //Bind to stop report branch on database based on user active flag.
    //Used to either or not display a screen saying that there's no livestream from the camera.
    bindStopReport: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('StopReport', database.ref('stop-reports/active/' + flag.toLowerCase()))
    }),
    bindTourStatus: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('CameraTourStatus', database.ref('georef/' + flag.toLowerCase() + '/tour'))
    }),
    unbindTourStatus: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('CameraTourStatus')
    }),
    bindEquipments: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('Equipments', database.ref('maintenance/equipments'))
    }),
    bindOnlineUsers: firebaseAction(({ bindFirebaseRef }) => {
        return bindFirebaseRef('OnlineUsers', database.ref('online-users'))
    }),
    unbindOnlineUsers: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('OnlineUsers')
    }),
    bindSites: firebaseAction(({ bindFirebaseRef }, flag) => {
        return bindFirebaseRef('Sites', database.ref('mordor-sites').orderByChild('flags/' + flag.toLowerCase()).equalTo(true))
    }),
    unbindSites: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('Sites')
    }),
    //Get all warning dates available on database by getting dates on date-index.
    //Payload: 'flagName':
    GetWarningsDatesAvailable(context, flag) {        
        return database.ref('sauron/' + flag.toLowerCase() + '/date-index').once('value')
            .then(snapshot => { return snapshot })
    },
    GetInactiveWarningsDatesAvailable(context, flag) {     
        return database.ref('sauron/' + flag.toLowerCase() + '/date-index-inactive').once('value')
            .then(snapshot => { return snapshot })
    },
    GetWarningsUnpaginatedFromDatabase(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/' + modifiedDate).orderByChild('datetime').limitToLast(payload.limitToLast).once('value')
            .then(snapshot => { return snapshot })
    },

    GetInactiveWarningsUnpaginatedFromDatabase(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/inactive/' + modifiedDate).orderByChild('datetime').limitToLast(payload.limitToLast).once('value')
            .then(snapshot => { return snapshot })
    },
    GetWarningsPaginatedFromDatabase(context, payload) {
        let modifiedDate = new Date(payload.date).toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/' + modifiedDate).endAt(payload.date).orderByChild('datetime').limitToLast(payload.limitToLast).once('value')
            .then(snapshot => { return snapshot })
    },
    GetWarningsCountFromDatabase(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/count/' + modifiedDate).once('value')
            .then(snapshot => { return snapshot })
    },
    GetInactiveWarningsCountFromDatabase(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/count-inactive/' + modifiedDate).once('value')
            .then(snapshot => { return snapshot })
    },
    //Get warnings on database based on user flag and date passed on payload.
    //Payload: {date: 'dd/mm/yyyy', flag: 'flagName'}
    //WIP: Make it so this action doesn't need to convert date from dd/mm/yyyy to dd-mm-yyyy as this action caller should provide that already.
    GetWarningsFromDatabaseByDate(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        if (payload.limitToLast) {
            return database.ref('sauron/' + payload.flag.toLowerCase() + '/' + modifiedDate).limitToLast(1).once('value')
                .then(snapshot => { return snapshot })
        } else {
            return database.ref('sauron/' + payload.flag.toLowerCase() + '/' + modifiedDate).once('value')
                .then(snapshot => { return snapshot })
        }
    },
    //Set sauron limits on its branch on database based on user active flag.
    //Payload: { flag: 'flagName', coordinates: [-23.45, -45.22, ...], include: true }
    SetSauronLimitsOnDatabase(context, payload) {
        let seconds = 60
        let area = 20
        database.ref('sauron/' + payload.flag.toLowerCase() + '/limits').set({ coordinates: payload.coordinates, include: payload.include, alertCeilingSeconds: seconds, alertCeilingArea: area })
    },
    //Set only sauron include limit on its branch on database based on user active flag.
    //Used when there are limits already defined and user wants to change only its include/exclude option.
    //Payload: { flag: 'flagName', include: true/false }
    SetSauronLimitsIncludeOnDatabase(context, payload) {
        database.ref('sauron/' + payload.flag.toLowerCase() + '/limits/include').set(payload.include)
    },
    //Remove sauron limits on its branch on dabase based on user active flag and set the include limit to its default (false).
    //Payload: 'flagName'
    RemoveSauronLimitsFromDatabase(context, flag) {
        database.ref('sauron/' + flag.toLowerCase() + '/limits/coordinates').remove()
        database.ref('sauron/' + flag.toLowerCase() + '/limits/include').set(false)
    },
    //Update a sensor message on gateways branch on database based on sensor MAC to mark it as seen or not seen.
    //Then, if user has seen it, push it to a log branch on database.
    //Payload: { mac: '', status: { seen: true/false, isFalseAlarm: true/fase }, device: '', date: '' }
    MarkGatewayMessageSeen(context, update) {
        database.ref('gateways/devices/sensor-' + update.mac + '/messages/message/').update(update.status).then(() => { //Updates entry of the last message received from this sensor to seen.
            database.ref('gateway-history/' + update.date).push(update.device) //Pushes the already seen message to a log.
            database.ref('gateway-history/last-message/message').once('value')
                .then(snapshot => {
                    if (snapshot.val()['payload_fields'].macAddress === update.mac) database.ref('gateway-history/last-message/message/seen').set(true)
                })
        })
    },
    //Get all incative stop report from its branch on database based on date passed.
    //Payload: 'dd-mm-yyyy'
    GetInactiveStopReportsFromDatabase(context, payload) {
        return database.ref('stop-reports/inactive/' + payload).once('value')
            .then((snapshot) => { return snapshot })
    },
    PushWarningToDatabase({ dispatch }, payload) {
        let userFlag = payload.warningObject.idxname.toLowerCase().replace('-backlog','')
        if (payload.warningObject.camera) userFlag += '/' +payload.warningObject.camera
        let dateString = new Date(payload.warningObject.datetime).toLocaleDateString('pt-BR').split('/').join('-')
        dispatch('GetWarningsCountFromDatabase', { date: new Date(payload.warningObject.datetime), flag: userFlag })
            .then(snapshot => {
                let warningCountOnDatabase = 0
                if (snapshot.exists()) warningCountOnDatabase = snapshot.val()
                warningCountOnDatabase += 1
                database.ref('sauron/' + payload.path).set(payload.warningObject)
                database.ref('sauron/' + userFlag + '/date-index/' + dateString).set(true)
                database.ref('sauron/' + userFlag  + '/count/' + dateString).set(warningCountOnDatabase)
            })
    },
    SetWarningsCache(context, payload) {
        context.commit('SET_WARNINGS_CACHE', payload)
    },
    //REFACTOR ALL THIS BLOCK
    //===============================================================================================
    //===============================================================================================
    SetRemovedWarning({ dispatch }, payload) {
        let path = payload.warning.idxname.toLowerCase().replace('-backlog','')
        if (payload.warning.camera) path += '/' +payload.warning.camera
        dispatch('GetInactiveWarningsCountFromDatabase', { date: new Date(payload.warning.datetime), flag: path })
            .then(snapshot => {
                let warningCountOnDatabase = 0
                if (snapshot.exists()) warningCountOnDatabase = snapshot.val()
                warningCountOnDatabase += 1
                database.ref('sauron/' + path + '/count-inactive/' + payload.date).set(warningCountOnDatabase)
                database.ref('sauron/' + path + '/date-index-inactive/' + payload.date).set(true)
            })
    },
    RemoveWarningFromDatabase({ dispatch }, payload) {
        let path = payload.warning.idxname.toLowerCase().replace('-backlog','')
        if (payload.warning.camera) path += '/' +payload.warning.camera
        dispatch('GetWarningsCountFromDatabase', { date: new Date(payload.warning.datetime), flag: path })
            .then(snapshot => {
                let warningCountOnDatabase = snapshot.val()

                database.ref('sauron/' + path + '/inactive/' + payload.date + '/' + payload.warningId).set(payload.warning)
                database.ref('sauron/' + path + '/' + payload.date + '/' + payload.warningId).remove()
                    .then(() => {
                        warningCountOnDatabase -= 1
                        if(warningCountOnDatabase > 0) {
                            return dispatch('SetWarningsCache', []) //TODO: Remove from specific warning from cache instead of blowing it entirely.
                                .then(() => {
                                    return database.ref('sauron/' + path + '/count/' + payload.date).set(warningCountOnDatabase)
                                })
                        } else {
                            return dispatch('SetWarningsCache', []) //TODO: Remove from specific warning from cache instead of blowing it entirely.
                                .then(() => {

                                    return database.ref('sauron/' + path + '/count/' + payload.date).remove()
                                })
                                .then(() => {
                                    return database.ref('sauron/' + path + '/date-index/' + payload.date).remove()
                                })
                        }
                    })
                    .then(() => {
                        return dispatch('SetRemovedWarning', payload)
                    })
            })
    },
    ReaproveInactiveWarning({ dispatch }, payload) {
        let path = payload.warning.idxname.toLowerCase().replace('-backlog','')
        if (payload.warning.camera) path += '/' +payload.warning.camera
        dispatch('GetInactiveWarningsCountFromDatabase', {date: new Date(payload.warning.datetime), flag: path})
            .then(snapshot => {
                let warningCountOnDatabase = 0
                if (snapshot.exists()) warningCountOnDatabase = snapshot.val()
                warningCountOnDatabase -= 1

                dispatch('PushWarningToDatabase', {
                    path: path + '/' + new Date(payload.warning.datetime).toLocaleDateString('pt-BR').split('/').join('-') + '/' + payload.warning.key,
                    warningObject: payload.warning
                })
                .then(() => {
                    if (warningCountOnDatabase <= 0) {
                        database.ref('sauron/' + path + '/count-inactive/' + payload.date).remove()
                        .then(() => {
                            return database.ref('sauron/' + path + '/date-index-inactive/' + payload.date).remove()
                        })
                    } else {
                        return database.ref('sauron/' + path + '/count-inactive/' + payload.date).set(warningCountOnDatabase)
                    }
                })
                .then(() => {
                    return database.ref('sauron/' + path + '/inactive/' + payload.date + '/' + payload.warningId).remove()
                })
            })

    },
    MarkSeenWarning({ getters, dispatch }, payload) { //TODO: MERGE WITH MARKTHUMBSUPWARNING.
      
        
        let warningsCache = getters.GetWarningsCache //Get warnings saved on cache.
        let warningInCache = warningsCache.find(warnings => warnings.idx === payload.warningIdx) //Find warning from payload in warnings cache array.
        let warningInCacheIndex = warningsCache.indexOf(warningInCache) //Save warning's from payload index on warnings cache array.
        warningInCache.seen = true //If warning not marked as seen yet, mark it.
        warningsCache.splice(warningInCacheIndex, 1, warningInCache) //Remove original warning object at specific index and push the new one marked as seen to the warnings cache array.
        dispatch('GetSeenWarningsCountFromDatabase', { flag: payload.warningIdxName.replace('-backlog',''), dateString: payload.warningDateString })
            .then(snapshot => {
                let seenWarningCountOnDatabase = snapshot.val()
                seenWarningCountOnDatabase += 1
                dispatch('SetWarningsCache', warningsCache)
                    .then(() => {
                        // console.log("1")
                        console.log('sauron/' + payload.warningIdxName.replace('-backlog','') + '/' + payload.warningDateString + '/' + payload.warningId + '/seen')
                        return database.ref('sauron/' + payload.warningIdxName.replace('-backlog','') + '/' + payload.warningDateString + '/' + payload.warningId + '/seen').once('value')
                            .then(snapshot => {
                                // console.log("2")
                                // console.log(snapshot)
                                // console.log('sauron/' + payload.warningIdxName.replace('-backlog','') + '/' + payload.warningDateString + '/' + payload.warningId + '/seen')
                                if (snapshot.exists()) {
                                    // console.log("3")
                                    database.ref('sauron/' + payload.warningIdxName.replace('-backlog','') + '/' + payload.warningDateString + '/' + payload.warningId + '/seen').set(true)
                                    return database.ref('sauron/' + payload.warningIdxName.replace('-backlog','') + '/count-seen/' + payload.warningDateString).set(seenWarningCountOnDatabase)
                                }
                            })
                    })
            })
    },
    MarkThumbsUpWarning({ getters, dispatch }, payload) {
        let warningsCache = getters.GetWarningsCache //Get warnings saved on cache.
        let warningInCache = warningsCache.find(warnings => warnings.idx === payload.warningIdx) //Find warning from payload in warnings cache array.
        let warningInCacheIndex = warningsCache.indexOf(warningInCache) //Save warning's from payload index on warnings cache array.
        warningInCache.thumbsUp = true
        warningsCache.splice(warningInCacheIndex, 1, warningInCache)
        return dispatch('SetWarningsCache', warningsCache)
            .then(() => {
                return database.ref('sauron/' + payload.warningIdxName + '/' + payload.warningDateString + '/' + payload.warningId + '/thumbsUp').set(true)
            })
            .then(() => {
                if (payload.approvedBy) {
                    return database.ref('sauron/' + payload.warningIdxName + '/' + payload.warningDateString + '/' + payload.warningId + '/approvedBy').set(payload.approvedBy)
                }
            })
    },
    MarkThumbsDownWarning({ getters, dispatch }, payload) {
        let warningsCache = getters.GetWarningsCache //Get warnings saved on cache.
        let warningInCache = warningsCache.find(warnings => warnings.idx === payload.warningIdx) //Find warning from payload in warnings cache array.
        let warningInCacheIndex = warningsCache.indexOf(warningInCache) //Save warning's from payload index on warnings cache array.
        warningInCache.thumbsDown = true
        warningsCache.splice(warningInCacheIndex, 1, warningInCache)
        return dispatch('SetWarningsCache', warningsCache)
            .then(() => {
                return database.ref('sauron/' + payload.warningIdxName + '/' + payload.warningDateString + '/' + payload.warningId + '/thumbsDown').set(true)
            })
            .then(() => {
                if (payload.disapproveddBy) {
                    return database.ref('sauron/' + payload.warningIdxName + '/' + payload.warningDateString + '/' + payload.warningId + '/disapproveddBy').set(payload.disapproveddBy)
                }
            })
    },
    GetSeenWarningsCountFromDatabase(context, payload) {
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/count-seen/' + payload.dateString).once('value')
            .then(snapshot => { return snapshot })
    },
    SetSeenWarningsCountOnDatabase(context, payload) {
        database.ref('sauron/' + payload.flag.toLowerCase() + '/count-seen/' + payload.dateString).set(payload.seenWarnings)
    },
    GetWarningsPresetFilteredFromDatabase(context, payload) {
        let modifiedDate = payload.date.toLocaleDateString('pt-br').split('/').join('-')
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/' + modifiedDate)
            .orderByChild('ptz/presetID').equalTo(payload.presetID).once('value')
            .then(snapshot => { return snapshot })
    },
    GetWarningsFiltersAvailable(context, payload) {
        return database.ref('sauron/' + payload.flag.toLowerCase() + '/filters/presets').once('value')
            .then(snapshot => { return snapshot })
    },
    //===============================================================================================
    //===============================================================================================
    GetMordorLocationsFromDatabase() {
        return database.ref('locations/mordor').once('value')
            .then(snapshot => { return snapshot })
    },
    //GENERIC ACTIONS REFACTOR
    PushToDatabase(context, payload) {
        return database.ref(payload.path).push(payload.object)
    },
    RemoveFromDatabase(context, payload) {
        return database.ref(payload.path).remove()
    },
    SetOnDatabase(context, payload) {
        console.log(payload)
        return database.ref(payload.path).set(payload.object)
    },
    GetFromDatabase(context, payload) {        
        return database.ref(payload.path).once('value')
            .then(snapshot => {
                return payload.returnSnapshotVal ? snapshot.val() : snapshot //Returns either snapshot's value or its entire object.
            })
    },

    GetLimitedFromDatabase(context, payload) {
        return database.ref(payload.path).limitToLast(payload.limit).once('value')
            .then(snapshot => {
                return payload.returnSnapshotVal ? snapshot.val() : snapshot //Returns either snapshot's value or its entire object.
            })
    },
    SetOnDisconnectHandler(context, payload) {
        switch (payload.method) {
            case 'cancel':
                return database.ref(payload.path).onDisconnect().cancel()
            case 'set':
                return database.ref(payload.path).onDisconnect().set(payload.object)
            case 'remove':
                return database.ref(payload.path).onDisconnect().remove()
            
        }
    },

    GetWarningsSauronAuxilary(context, payload) {
        return database.ref(payload).limitToLast(6).once('value')
    },


    GetCamerasFromDatabase(context, flag) {
        state.Cameras = []
        return database.ref(`sauron/${flag.toLowerCase()}/camcontrol`).once('value')
            .then((resp) => {
                resp.forEach((element) => {
                    if (element.key != 'camx') state.Cameras.push(element.key)
                })
                state.CurrentCamera = state.Cameras[0]
            })
    },
    SwitchCurrentUserActiveCam(context, cam) {
        //TODO: get image from camera
        state.CurrentCamera = cam
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}